<template>
    <modal ref="modalEditarPopUp" titulo="Editar" no-aceptar adicional="Guardar" :cargado-modal="loading" @adicional="guardar()">
        <ValidationObserver ref="form" class="w-100">
            <div class="row mx-0 py-3 justify-center">
                <slim-cropper 
                ref="imgAnuncio" 
                :options="slimOptions" 
                class="border cr-pointer slimPopUp" 
                />
                <div class="col-12 mt-4">
                    <!-- <p class="text-general f-13 pl-3">Vigencia</p> -->
                    <el-date-picker
                    v-model="fechas"
                    class="w-100"
                    type="datetimerange"
                    size="small"
                    range-separator="-"
                    start-placeholder="Inicio"
                    end-placeholder="Fin"
                    value-format="yyyy-MM-dd HH:mm"
                    format="dd-MMM-yyyy hh:mm a"
                    />
                </div>
                <div class="col-12 px-4 mt-3 d-flex">
                    <el-checkbox v-model="model.estado" class="check-dark" :true-label="0" :false-label="1">
                        Inactivo
                    </el-checkbox>
                </div>
            </div>
            <div class="row mx-0 py-2" />
            <div class="row mt-2 mx-0 justify-center">
                <p class="col-12 f-14 text-general">
                    Redirigir a:
                </p>
                <el-radio-group v-model="redireccion" class="px-3 mt-4 options-pop" @change="onChangeRedireccion()">
                    <el-radio class="radio-dark" :label="0">
                        Sin redirección
                    </el-radio>
                    <el-radio class="radio-dark" :label="1">
                        Categoría
                    </el-radio>
                    <el-radio class="radio-dark" :label="2">
                        Promoción
                    </el-radio>
                    <el-radio class="radio-dark" :label="3">
                        Producto
                    </el-radio>
                </el-radio-group>
            </div>
            <div v-if="redireccion != 0" class="row mx-0 justify-center mt-3">
                <div class="col-12">
                    <p class="pl-3 text-general f-12">Seleccionar</p>
                    <ValidationProvider v-slot="{ errors }" :rules="{required: redireccion != 0}" class="w-100" name="redirección">
                        <el-select v-model="idRedireccion" placeholder="Seleccionar" size="small" class="w-100" filterable>
                            <el-option
                            v-for="item in recordsRedireccion"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                        <p class="f-11 text-danger">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Publicidad from "~/services/publicidad/publicidad_admin";

export default {
    data(){
        return {
            redireccion: 0,
            idRedireccion: null,
            recordsRedireccion: [],
            value1: '',
            loading: false,
            value: '',
            fechas: null,
            model: {},
            slimOptions: {
                ratio: '2:3',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
        }
    },
    methods: {
        async toggle(model){
            this.$refs.modalEditarPopUp.toggle();
            this.loading = true
            let newModel = _.cloneDeep(model)
            this.model = {...newModel}
            this.redireccion = newModel.redireccion
            await this.onChangeRedireccion()
            this.idRedireccion = (newModel.id_categoria || newModel.id_promocion || newModel.id_producto)
            this.fechas = (newModel.fecha_inicio && newModel.fecha_fin) ? [newModel.fecha_inicio, newModel.fecha_fin]: null
            if (newModel.imagen){
                this.$refs.imgAnuncio.set_image(`${newModel.imagen}`)
            } else {
                this.$refs.imgAnuncio.instanciaCrop.remove()
            }
            this.loading = false
        },
        async guardar(){
            try {
                const valid = await this.$refs.form.validate()
                if(!valid) return
                this.loading = true
                let model = {}
                const archivo = this.$refs.imgAnuncio.instanciaCrop.dataBase64.output.image
                if(!archivo){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                model.imagen = archivo
                model.fecha_inicio = this.fechas && this.fechas[0]
                model.fecha_fin = this.fechas && this.fechas[1]
                model.redireccion = this.redireccion
                model.estado = this.model.estado
                model.id_redireccion = this.idRedireccion
                model.id = this.model.id
                model.id_publicidad = this.model.id_publicidad

                const {data} = await Publicidad.editarPublicidad(model)
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.$emit('update', data.model)
                
                this.$refs.modalEditarPopUp.toggle();

            } catch (e){
                this.error_catch(e);
            } finally {
                this.loading = false
            }
        },
        async onChangeRedireccion(){
            this.idRedireccion = null
            this.recordsRedireccion = []

            await this.buscarRedireccion()
        },
        async buscarRedireccion(){
            try {
                let params = {
                    redireccion: this.redireccion,
                    cedis: this.model.id_cedis
                }
                const { data } = await Publicidad.getRedireccion(params)
                this.recordsRedireccion = data.records

            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.slimPopUp{
    height:146px;
    width:146px;
    background-color:#F5F5F5;
    border-radius:2px;
}
.options-pop{
    display: grid;
    grid-template: 25px 25px / 1fr 2fr;
}
</style>